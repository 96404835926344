<template>
  <a-tooltip
    placement="left"
    trigger="click"
    role="button"
    @visibleChange="onClick"
    v-if="value && $auth.granted(acl.shipments.monitor)">
    <template slot="title">
      <template v-if="name">
        <span>{{ name }}</span>
      </template>
      <template v-else>
        <a-icon type="loading" class="mr-2" />
        <span>{{ $t('loading') }}</span>
      </template>
    </template>
    <div class="text-muted font-size-12 font-weight-light">
      {{ $t('msg.shipment.assignedTo') }} #{{ value }}
    </div>
  </a-tooltip>
</template>

<script>
import acl from '@/auth/acl'
import lms from '@/api/lms'
export default {
  name: 'LabelCourier',
  props: [
    'value',
  ],
  data: function () {
    return {
      acl,
      name: undefined,
    }
  },
  methods: {
    onClick(visible) {
      if (visible) {
        lms.suggestion.search(this.value, 'COURIER')
          .then(r => {
            if (r.data && r.data.length > 0) {
              this.name = r.data[0].value
            }
          })
      }
    },
  },
}
</script>
