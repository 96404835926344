<template>
  <div class="container">
    <div class="row align-items-center">

      <!-- Checkbox -->

      <div class="col-auto d-none d-lg-block" v-if="checkable">
        <a-checkbox v-model="checkbox" />
      </div>

      <!-- Type -->

      <div class="col-6 col-sm-3 col-lg-2">
          <router-link
            class="code text-monospace"
            :to="`/shipments/${shipment.code}/detail`"
            v-if="shipment.code">
            <span ref="code">{{shipment.code}}</span>
          </router-link>
        <span
          role="button"
          class="copy"
          @click="() => onCopy(shipment.code)"
          v-if="shipment.code">
          <a-icon type="copy" class="text-primary" />
        </span>
        <div class="mt-3">
          <slot name="direction">
            <a-tag
              v-if="shipment.direction"
              :color="direction[shipment.direction].color">
              <a-icon :type="direction[shipment.direction].icon" />
            </a-tag>
          </slot>
          <span class="text-dark">
            {{$t(`enum.type.${shipment.type}`)}}
          </span>
        </div>
      </div>

      <!-- Order -->

      <div class="col-6 col-lg-2 d-none d-lg-block">
        <div class="font-size-12 text-dark text-monospace mb-2" v-if="shipment.ref">
          #<span class="user-select-all">{{shipment.ref}}</span>
        </div>
        <div>
          <a-avatar :src="`/resources/images/platforms/${shipment.platform}.png`" size="small"/>
          <span class="font-size-12 text-secondary">
            {{ $t(`enum.source.${shipment.platform}`) }}
          </span>
          <span class="font-size-10 font-weight-bold font-italic text-success" v-if="shipment.platform === 'MLI'">
            <a-divider type="vertical" />
            <span v-if="shipment.tags.indexOf('TURBO') === -1">FLEX</span>
            <span v-else>TURBO</span>
          </span>
        </div>
        <div class="mt-1 font-size-12" v-if="shipment.platform === 'OMN' && shipment.twin">
          {{ $t('package') }} #<strong>{{ shipment.number }}</strong>
        </div>
        <div class="mt-1 font-size-12 text-lowercase" v-else>
          {{ $t('msg.shipment.packages', { count: shipment.packages }) }}
        </div>
      </div>

      <!-- Origin / Destination -->

      <div class="col-12 col-sm-5 col-lg-4 d-none d-sm-block">
        <a-steps size="small" direction="vertical">
          <a-step status="process" v-if="shipment.origin">
            <template #icon>
              <span class="icon-dot font-size-18">⦿</span>
            </template>
            <template #title>
              <div class="font-size-14" v-if="shipment.origin.contact">
                {{ shipment.origin.contact.name }}
              </div>
              <div class="font-size-14" v-else>
                --
              </div>
            </template>
            <template #description>
              <div class="font-size-12 text-secondary">
                {{ shipment.origin.address.street }} - {{ shipment.origin.address.city }}, {{ shipment.origin.address.state }}
              </div>
            </template>
          </a-step>
          <a-step status="process" v-if="shipment.destination">
            <template #icon>
              <span class="icon-pin fe fe-map-pin font-size-16 text-danger" />
            </template>
            <template #title>
              <div class="font-size-14" v-if="shipment.destination.contact">
                {{ shipment.destination.contact.name }}
              </div>
              <div class="font-size-14" v-else>
                --
              </div>
            </template>
            <template #description>
              <div class="font-size-12 text-secondary">
                {{ shipment.destination.address.street }} - {{ shipment.destination.address.city }}, {{ shipment.destination.address.state }}
              </div>
            </template>
          </a-step>
        </a-steps>
      </div>

      <!-- Status -->

      <div class="col-6 col-sm-4 col-lg-2">
        <slot name="status">
          <a-row type="flex">
            <a-col flex="42px">
              <a-avatar
                class="float-left pt-1"
                :style="`backgroundColor:${state.style.color}`">
                <i class="font-size-18 fe" :class="state.style.icon"/>
              </a-avatar>
            </a-col>
            <a-col flex="auto">
              <div class="text-dark font-size-16">
                {{ state.title }}
              </div>
              <div class="font-size-12 font-weight-light">
                {{ state.subtitle }}
              </div>
              <div class="font-size-12">
                {{ shipment.promoted | datetime }}
              </div>
              <j-courier :value="shipment.courier" />
            </a-col>
            <!-- <a-col flex="40px" class="d-none d-lg-flex align-items-center">
              <a-tooltip placement="top" v-if="delay">
                <template slot="title">
                  <span>{{ $t('delayed') }}</span>
                </template>
                <span class="text-danger">
                  <a-icon type="fire"/>
                  {{ delay | delayed }}
                </span>
              </a-tooltip>
            </a-col> -->
          </a-row>
        </slot>
      </div>

      <!-- <div class="col-6 col-lg-2">
        <slot name="status">
          <div class="row">
            <div class="col-2 mt-1">
              <a-avatar
                class="pt-1"
                :style="`backgroundColor:${state.style.color}`">
                <i class="font-size-18 fe" :class="state.style.icon"/>
              </a-avatar>
            </div>
            <div class="col-8">
              <div class="text-dark font-size-16">
                {{ state.title }}
              </div>
              <div class="font-size-12 font-weight-light">
                {{ state.subtitle }}
              </div>
              <div class="font-size-12">
                {{ shipment.promoted | datetime }}
              </div>
              <j-courier :value="shipment.courier" />
            </div>
          </div>
        </slot>
      </div> -->

      <!-- Action -->

      <div class="col d-none d-lg-block">
        <slot name="cta"></slot>
      </div>

    </div>
  </div>
</template>
<script>
import acl from '@/auth/acl'
import moment from 'moment'
import JCourier from '@/views/shared/labels/courier'
import { status, direction } from '@/utils/styles'

export default {
  name: 'Shipment',
  props: {
    shipment: Object,
    checkable: Boolean,
    checked: Boolean,
  },
  components: {
    JCourier,
  },
  data: function () {
    return {
      status,
      direction,
    }
  },
  computed: {
    state() {
      // prepare the default values
      const key = this.shipment.sub_status || this.shipment.status
      const title = this.$t(`enum.status.${key}`)
      let subtitle = null
      let style = status[key]
      if (this.shipment.status === 'TO_COLLECT') {
        // show labeling info
        if (this.shipment.printed) {
          subtitle = this.$t('msg.shipment.label.printed')
          style = status.PRINTED
        } else {
          subtitle = this.$t('msg.shipment.label.pending')
        }
      } else if (this.shipment.status.startsWith('OUT')) {
        // show in transit info
        subtitle = this.$t(`enum.status.${this.shipment.status}`)
      } else if (this.shipment.sub_status === 'BLOCKED') {
        // show issue on blocked
        subtitle = this.$t(`enum.issue.${this.shipment.issue}`)
      }
      return {
        title: title,
        subtitle: subtitle,
        style: style,
      }
    },
    delay() {
      const now = moment()
      const point = this.shipment.direction === 'FORWARD'
        ? this.shipment.destination
        : this.shipment.origin
      if (
        this.shipment.started &&
        !this.shipment.finished &&
        this.shipment.visits === 0 &
        this.$auth.granted(acl.shipments.monitor) &&
        point.schedule &&
        point.schedule.max &&
        moment(point.schedule.max).isBefore(now)) {
        return point.schedule.max
      }
      return null
    },
    checkbox: {
      get() {
        return this.checked
      },
      set(value) {
        this.$emit('check', { code: this.shipment.code, checked: value })
      },
    },
  },
  methods: {
    zone(point) {
      return point.zone
        ? `${this.$t('zone')} ${point.zone.rate}`
        : this.$t('msg.undefined', { name: this.$t('zone') })
    },
    onCopy(code) {
      navigator.clipboard.writeText(code)
      this.$message.success(this.$t('msg.copied'))
    },
  },
}
</script>
<style lang="scss">
.code {
  color: black;
  font-size: 20px;
  font-weight: 600;
}
.copy {
  display: inline-flex;
  margin-left: 5px;
}
.icon-dot {
  padding-left: 5px;
}
.icon-pin {
  padding-left: 4px;
}
</style>
