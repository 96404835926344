<template>
  <span>
    <a-dropdown :trigger="['click']">
      <a-menu slot="overlay">
        <a-menu-item
          @click="onPrint"
          v-if="$auth.granted(acl.shipments.print)">
          <a-icon type="printer" class="text-secondary" />
          {{ $t('msg.shipment.action.printOne') }}
        </a-menu-item>
        <a-menu-item
          @click="onPrintFlex"
          v-if="$auth.granted(acl.shipments.monitor) && flex">
          <a-icon type="qrcode" class="text-secondary" />
          {{ $t('msg.shipment.action.printFlex') }}
        </a-menu-item>
        <a-menu-item
          @click="onPrepareFlex"
          v-if="!$auth.granted(acl.shipments.monitor) && flex">
          <a-icon type="printer" class="text-secondary" />
          {{ $t('msg.shipment.action.printFlex') }}
        </a-menu-item>
        <a-menu-item
          @click="onPrintOutsource"
          v-if="$auth.granted(acl.shipments.monitor) && shipment.outsource">
          <a-icon type="qrcode" class="text-secondary" />
          {{ $t('msg.shipment.action.printOutsource') }}
        </a-menu-item>
        <a-menu-item
          @click="onEdit"
          v-if="$auth.granted(acl.shipments.update)">
          <a-icon type="form" class="text-secondary" />
          {{ $t('msg.shipment.action.edit') }}
        </a-menu-item>
        <a-menu-item
          @click="onViewItems"
          v-if="$auth.granted(acl.shipments.items)">
          <a-icon type="shopping-cart" class="text-secondary" />
          {{ $t('msg.shipment.action.viewItems') }}
        </a-menu-item>
        <a-menu-item
          @click="onPromote"
          v-if="$auth.granted(acl.shipments.promote)">
          <a-icon type="notification" class="text-secondary" />
          {{ $t('msg.shipment.action.promote') }}
        </a-menu-item>
        <a-menu-item
          @click="onViewCourier"
          v-if="$auth.granted(acl.shipments.monitor) && shipment.courier">
          <a-icon type="car" class="text-secondary" />
          {{ $t('msg.shipment.action.viewCourier') }}
        </a-menu-item>
        <a-menu-item
          @click="onRecover"
          v-if="recoverable && $auth.granted(acl.shipments.create)">
          <a-icon type="arrow-left" class="text-secondary" />
          {{ $t('msg.shipment.action.recover') }}
        </a-menu-item>
        <a-menu-item
          @click="onExchange"
          v-if="recoverable && $auth.granted(acl.shipments.create)">
          <a-icon type="swap" class="text-secondary" />
          {{ $t('msg.shipment.action.exchange') }}
        </a-menu-item>
        <a-menu-item
          @click="onOutsource"
          v-if="$auth.granted(acl.shipments.monitor) && !shipment.outsource">
          <a-icon type="share-alt" class="text-secondary" />
          {{ $t('msg.shipment.action.outsource') }}
        </a-menu-item>
        <a-menu-item
          @click="onBlock"
          v-if="$auth.granted(acl.shipments.monitor)">
          <a-icon type="lock" class="text-secondary" />
          {{ $t('msg.shipment.action.block') }}
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item
          class="text-danger"
          @click="onRevert"
          v-if="$auth.granted(acl.shipments.monitor)">
          <a-icon type="rollback" class="text-danger" />
          {{ $t('msg.shipment.action.revert') }}
        </a-menu-item>
        <a-menu-item
          class="text-danger"
          @click="onReport"
          v-if="$auth.granted(acl.shipments.monitor)">
          <a-icon type="flag" class="text-danger" />
          {{ $t('msg.shipment.action.report') }}
        </a-menu-item>
        <a-menu-item
          class="text-danger"
          @click="onCancel"
          v-if="$auth.granted(acl.shipments.cancel)">
          <a-icon type="stop" class="text-danger" />
          {{ $t('msg.shipment.action.cancel') }}
        </a-menu-item>
      </a-menu>
      <slot name="button">
        <a-button
          size="large"
          type="primary"
          style="margin-left: 8px">
          {{ $t('actions') }}<a-icon type="down" />
        </a-button>
      </slot>
    </a-dropdown>
    <j-pdf
      v-if="label"
      :src="label"
      :visible="label"
      @close="label = null" />
  </span>
</template>
<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import { Modal } from 'ant-design-vue'
import JIssue from './issue'
import JPdf from '@/views/shared/cards/pdf'
export default {
  components: {
    JPdf,
  },
  props: {
    shipment: Object,
  },
  data: function () {
    return {
      acl,
      issue: undefined,
      label: undefined,
    }
  },
  computed: {
    flex() {
      return this.shipment.platform === 'MLI'
    },
    recoverable() {
      return this.shipment.status === 'DELIVERED'
    },
    suffix() {
      return this.$route.path.split('/').pop()
    },
  },
  methods: {
    onPrint() {
      const filters = { code: this.shipment.code }
      const printer = this.$store.state.settings.printer
      lms.label.download(filters, printer)
        .then(response => window.open(response.data.url, '_blank'))
    },
    onPrepareFlex() {
      const shopId = this.shipment.shop
      const id = this.shipment.external
      const printer = this.$store.state.settings.printer
      lms.platform.fetchLabel(shopId, id, printer)
        .then(response => window.open(response.data.url, '_blank'))
    },
    onPrintFlex() {
      this.label = `https://jipink.s3.us-west-2.amazonaws.com/lms/flex-${this.shipment.ref}.pdf#view=FitV`
    },
    onPrintOutsource() {
      this.label = `https://jipink.s3.us-west-2.amazonaws.com/lms/csa-${this.shipment.outsource}.pdf#view=FitV`
    },
    onOutsource() {
      this.onActionUpdate('outsource')
    },
    onViewCourier() {
      this.$router.push(`/couriers/${this.shipment.courier}/detail`)
    },
    onEdit() {
      this.$router.push(`/shipments/${this.shipment.code}/update`)
    },
    onPromote() {
      this.$router.push(`/shipments/${this.shipment.code}/promote`)
    },
    onViewItems() {
      this.$router.push(`/shipments/${this.shipment.code}/items`)
    },
    onRecover() {
      this.onActionRedirect('recover')
    },
    onExchange() {
      this.onActionRedirect('exchange')
    },
    onRevert() {
      this.onActionUpdate('revert')
    },
    onReport() {
      this.onActionUpdate('report')
    },
    onCancel() {
      this.onActionRedirect('cancel')
    },
    onBlock() {
      Modal.confirm({
        title: this.$t('msg.shipment.action.block'),
        content: h => <JIssue vModel={this.issue} />,
        okText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        okType: 'danger',
        onOk: () => {
          return new Promise((resolve, reject) => {
            if (this.issue) {
              resolve(
                lms.shipment.block(this.shipment.id, this.issue)
                  .then(r => this.$emit('update', r.data)),
              )
            } else {
              reject(Error('no issue selected'))
            }
          })
        },
      })
    },
    onActionUpdate(name) {
      Modal.confirm({
        title: this.$t(`msg.shipment.action.${name}`),
        content: this.$t(`msg.shipment.confirm.${name}`),
        okText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        okType: 'danger',
        onOk: () => {
          lms.shipment[name](this.shipment.id)
            .then(r => this.$emit('update', r.data))
        },
      })
    },
    onActionRedirect(name) {
      Modal.confirm({
        title: this.$t(`msg.shipment.action.${name}`),
        content: this.$t(`msg.shipment.confirm.${name}`),
        okText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        okType: 'danger',
        onOk: () => {
          lms.shipment[name](this.shipment.id)
            .then(response => {
              const code = response.data.code || this.shipment.code
              this.$router.push(`/shipments/success?type=${name}&code=${code}`)
            })
        },
      })
    },
  },
}
</script>
