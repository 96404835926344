<template>
  <div>
    <p>{{ $t('msg.shipment.confirm.block') }}</p>
    <a-select
      v-model="selected"
      :placeholder="$t('msg.shipment.blocked.title')"
      style="width: 100%"
      @change="$emit('input', selected)">
      <a-select-option
        v-for="key in values"
        :key="key" :value="key">
        {{ $t(`msg.issue.${key}`) }}
      </a-select-option>
    </a-select>
  </div>
</template>
<script>
import { i18n } from '@/localization'
export default {
  i18n,
  data: function () {
    return {
      selected: undefined,
    }
  },
  computed: {
    values() {
      return [
        'BUSINESS_HOURS',
        'INCORRECT_ADDRESS',
        'LOST',
        'ON_VACATIONS',
        'OVERSIZE',
        'REJECTED',
        'STOLEN',
        'UNREACHABLE_AREA',
        'UNREACHABLE_RECEIVER',
        'BROKEN',
      ]
    },
  },
}
</script>
