<template>
  <a-drawer
      placement="right"
      :closable="true"
      :visible="visible"
      :width="600"
      :body-style="{ 'padding': 0, 'height': '100%', }"
      @close="() => $emit('close')">
    <iframe
      :src="src"
      frameborder="0" />
    </a-drawer>
</template>

<script>
export default {
  props: ['src', 'visible'],
}
</script>

<style scoped>
iframe {
  width: 100%;
  height: 100%;
}
</style>
